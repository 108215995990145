body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.toast-error {
    background-color: #ce4141 !important;
    border: 1px solid #cc2525;
}

.toast-success {
    background-color: #5bb13a !important;
    border-color: 1px solid #3b8020;
}

.toast-error,
.toast-success {
    color: white !important;
    font-size: 0.6em;
    border-radius: 0.2em !important;
}